<template>
  <div
    class="loader-wrapper loader-full"
    :style="'position: ' + display + ' !important'"
  >
    <div class="loader" title="Cargando ...">
      <svg class="circular-loader" viewBox="25 25 50 50">
        <circle
          class="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#09f"
          stroke-width="4"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",

  props: {
    display: { type: String, default: "fixed" },
  },
};
</script>
